(function () {
    'use strict';

    angular.module('aerosApp').factory('ProjectInfoModal', ProjectInfoModal);

    ProjectInfoModal.$inject = ['$uibModal'];

    function ProjectInfoModal($uibModal) {
        return {
            open: open
        };

        function open(project) {
            return $uibModal.open({
                size: "md",
                templateUrl: '/static/templates/directive/project-info-modal.html',
                controller: ["$scope", "project", function ($scope, project) {
                    $scope.project = project;
                }],
                resolve: {
                    project: function () {
                        return project;
                    }
                }
            }).result;
        }
    }
})();